@tailwind base;
@tailwind components;
@tailwind utilities;

button[type='submit'] {
  @apply bg-darkblue_100;
}

/*スクロールバー全体*/
::-webkit-scrollbar {
  width: 8px; /* 縦方向スクロールバーの幅 */
  height: 8px; /* 横方向スクロールバーの幅 */
}

/*スクロールバーの軌道*/
::-webkit-scrollbar-track {
  /*  border-radius: 10px; */
  box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.1);
}

/*スクロールバーの動く部分*/
::-webkit-scrollbar-thumb {
  background-color: #030159;
  /* border-radius: 10px; */
  box-shadow: inset 0 0 1px rgba(255, 255, 255, 0.3);
}
